/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  background-color: #FEFAE0;
  color: #A06933;
  border: 2px solid #D4A373;
  border-radius: 3px;
  padding: 3px 6px;
}

input:focus {
  outline: none;
}

.inputCounters {
  padding: 10px;
}

.rButton {
  position: relative;
  padding: 40px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 15px;
}

.rButton button {
  position: absolute;
  right: 10px;
}

.unit {
  font-size: 15px;
  color: #A06933;
  font-weight: 550;
  float: left;
  height: 47px;
  display: flex;
  align-items: center;
}

.inputGroup {
  float: left;
}

.icon {
  margin-left: 8px;
}

#tf-frequency, #tf-voltage {
  padding: 3px 6px;
  background-color: #FEFAE0;
  border: 2px solid #D4A373;
  border-radius: 3px;
  color: #A06933;
  font-weight: 550;
}

#usb-panel {
  display: block;
  padding-left: 20px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(212, 163, 115, 0.25));
  border-bottom: 1px solid #D4A373;
}

#usb-connect-status {
  color: #A06933;
  font-weight: 500;
  height: 25px;
  display: flex;
  align-items: center;
}
